import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getCalendarColor, getEventsForCalendar } from 'utils/calendar';
import './MasterCalendarPage.scss';
import { useHeader } from 'hooks/usePageActions';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';
import { colors } from 'utils/styles';
import Button from 'components/FormUI/Button';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import * as calendarsActions from 'actions/calendars';
import { darken } from 'polished';
import AddIcon from '@mui/icons-material/Add';
import Loader from 'components/UI/Loader';
import ScheduleMeetingModal from 'pages/ContributionView/components/CalendarContributionView/ScheduleMeetingModal';
import { fetchContributionActions, rescheduleSession as rescheduleSessionAction } from 'actions/contributions';
import { getMasterCalendarEvents } from 'services/calendars.service';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import ConfirmationModal from 'pages/ContributionView/ContributionCommunity/components/ConfirmationModal';
import RefuseModal from 'pages/ContributionView/components/RefuseModal';
import { formatTime } from 'utils/utils';
import DateNavigationComponent from './components/DateNavigator';
import ExternalCalendarVisibilityComponent from './components/ExternalCalendarVisibility';
import ReschduleSessionPopup from './components/ReschedulePopup';
import ExternalCalendarSettingsPopup from './components/ExternalCalendarSettingsPopup';
import EventsPopup from './components/EventsPopup';
import { ContributionType } from 'helpers/constants';
import { redirectTo } from 'services/links';
import { AvailabilityModal } from './components/availabilityModal';
import CustomEvent from './components/CustomEventsUI';
import { isArray } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getCohealerContribution } from 'services/contributions.service';
import Tooltip from '@mui/material/Tooltip';
import EasyBooking from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/EasyBooking';
import SessionBookingThankyou from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/components/SessionBookingThankyou';
import { useMediaQuery, useTheme } from '@material-ui/core';

const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);

const MasterCalendarPage = ({
  addCalendar,
  calendars,
  fetchCalendars,
  removeCalendar,
  calendarActiveView,
  isloading,
  rescheduleChosenSession,
  calendarSignInCallback,
}) => {
  useHeader('Calendar');
  const query = new URLSearchParams(useLocation().search);
  const code = useMemo(() => query.get('code'), [query]);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [showSelfbookModal, setShowSelfbookModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showExternalCalendarSettingsModal, setShowExternalCalendarSettingsModal] = useState(false);
  const [
    showExternalCalendarConnectionCompleteStepsModal,
    setShowExternalCalendarConnectionCompleteStepsModal,
  ] = useState({
    showStep1: false,
    showStep2: false,
  });
  const [calendarForSettingsPopup, setCalendarForSettingsPopup] = useState(false);
  const [isSessionLoading, setIsSessionLoading] = useState(isloading);
  const [showAvailabilityPopUp, setshowAvailabilityPopUp] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rescheduledSession, setRescheduledSession] = useState(null);
  const [events, setEvents] = useState([]);
  const [contribution, setContribution] = useState(null);
  const [showCustomMessageModal, setShowCustomMessageModal] = useState(false);
  const [showSuccessfulReschedulePop, setShowSuccessfulReschedulePop] = useState(false);
  const [showUnsuccessfulReschedulePop, setShowUnsuccessfulReschedulePop] = useState(false);
  const [isRescheduleLoading, setIsRescheduleLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const [messageText, setMessageText] = useState('');
  const [prevView, setPrevView] = useState('');
  const [showEventsPopup, setShowEventsPopup] = useState(false);
  const [showExternalCalendarEventPopup, setShowExternalCalendarEventPopup] = useState(false);
  const [currentViewDateRange, setCurrentViewDateRange] = useState('');
  const [selectedDateEvents, setSelectedDateEvents] = useState({
    date: new Date(),
    events: [],
  });
  const [fetchEvents, setfetchEvents] = useState(false);
  const contributionsForTable = useSelector(s => s?.upcomingCreated?.upcomingCreated?.contributionsForTable || null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedEmailToRemove, setSelectedEmailToRemove] = useState('');
  const [openSettings, setOpenSettings] = useState(false);
  const { lastAddedEmail, loadingLastEmail } = useSelector(s => s.calendars);
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);

  const refetchEvents = () => {
    fetchCalendars().then(() => {
      handleStartAndEndDateOfView(currentViewDateRange, '', true);
      setfetchEvents(false);
    });
  };

  useEffect(() => {
    if (currentViewDateRange && fetchEvents) {
      refetchEvents();
    }
  }, [currentViewDateRange, fetchEvents]);

  useEffect(() => {
    if (code) {
      calendarSignInCallback(code, true).then(() => {
        setfetchEvents(true);
        query.delete('code');
        history.replace({
          search: query.toString(),
        });
      });
    }
  }, [code]);
  // useEffect(() => {
  //   if (code) {
  //     // TODO: here open the popup after connecting 1st calendar to complete setup
  //     if (isArray(calendars) && calendars.length === 1) {
  //       setCalendarForSettingsPopup(calendars.at(0));
  //       setShowExternalCalendarConnectionCompleteStepsModal({
  //         ...showExternalCalendarConnectionCompleteStepsModal,
  //         showStep1: true,
  //         showStep2: false,
  //       });
  //       query.delete('code');
  //       history.replace({
  //         search: query.toString(),
  //       });
  //     }
  //   }
  // }, [calendars]);
  useEffect(() => {
    if (!loadingLastEmail && lastAddedEmail) {
      // TODO: here open the popup after connecting 1st calendar to complete setup
      if (isArray(calendars) && calendars.length) {
        const recentAddedAccount = calendars.find(k => k.emailAddress === lastAddedEmail);
        if (recentAddedAccount) {
          setCalendarForSettingsPopup(recentAddedAccount);
          setShowExternalCalendarConnectionCompleteStepsModal({
            ...showExternalCalendarConnectionCompleteStepsModal,
            showStep1: true,
            showStep2: false,
          });
        }
      }
    }
  }, [calendars, lastAddedEmail, loadingLastEmail]);
  // useEffect(() => {
  //   if (code) {
  //     // TODO: here open the popup after connecting 1st calendar to complete setup
  //     if (isArray(calendars) && calendars.length === 1) {
  //       setCalendarForSettingsPopup(calendars.at(0));
  //       setShowExternalCalendarConnectionCompleteStepsModal({
  //         ...showExternalCalendarConnectionCompleteStepsModal,
  //         showStep1: true,
  //         showStep2: false,
  //       });
  //       query.delete('code');
  //       history.replace({
  //         search: query.toString(),
  //       });
  //     }
  //   }
  // }, [calendars]);

  useEffect(() => {
    if (!showSelfbookModal && !isRescheduleModalOpen) {
      dispatch(fetchContributionActions.success(null));
    }
  }, [showSelfbookModal, isRescheduleModalOpen]);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const onRescheduleSlotSelection = slot => {
    setSelectedSlot(slot);
    setShowCustomMessageModal(true);
    setShowRescheduleModal(false);
  };

  const onEventClick = event => {
    if (event.contributionType === ContributionType.contributionOneToOne) {
      redirectTo(`/contribution-view/${event?.contributionId}/sessions?sessionId=${event.id || event.sessionId}`);
    } else if (event?.isExternalEvent) {
      setShowExternalCalendarEventPopup(true);
    } else if (event.contributionType !== ContributionType.contributionOneToOne && event?.contributionId) {
      redirectTo(`/edit-contribution/${event?.contributionId}/sessions`);
    }
  };

  const handleStartAndEndDateOfView = (range, view, externalCalendarUpdate = false) => {
    if (view === prevView && !externalCalendarUpdate) return;
    setPrevView(view || prevView);
    let viewStartDate;
    let viewEndDate;
    if (Array.isArray(range)) {
      // Handle the case when range is an array of Date objects in week and day view
      viewStartDate = range[0];
      viewEndDate = range[range.length - 1];
    } else if (typeof range === 'object' && range?.start && range?.end) {
      // Handle the case when range is an object with start and end properties usually in month view
      viewStartDate = range.start;
      viewEndDate = range.end;
    }
    setCurrentViewDateRange(range);
    viewEndDate = new Date(viewEndDate.getTime());
    setIsSessionLoading(true);
    getEventsForCalendar({ viewStartDate, viewEndDate })
      .then(allEvents => {
        setEvents(allEvents);
      })
      .finally(() => {
        setIsSessionLoading(false);
      });
  };

  const onReschedule = () => {
    const { offset, id: rescheduleToId } = selectedSlot;
    const { contributionId, id: rescheduleFromId } = rescheduledSession;

    setIsRescheduleLoading(true);
    rescheduleChosenSession({
      contributionId,
      note: messageText,
      offset,
      rescheduleFromId,
      rescheduleToId,
    })
      .then(data => {
        if (typeof data?.payload === 'string' && data.payload.includes('already booked')) {
          setShowUnsuccessfulReschedulePop(true);
        } else {
          handleStartAndEndDateOfView(currentViewDateRange, '', false);
          setShowSuccessfulReschedulePop(true);
        }
      })
      .finally(() => {
        setShowCustomMessageModal(false);
        setShowEventsPopup(false);
        setIsRescheduleLoading(false);
        setMessageText(null);
      });
  };

  const isDisabled =
    contributionsForTable?.filter(
      contribution => contribution.type === 'ContributionOneToOne' && contribution.status == 'Approved',
    ).length === 0;

  const CustomToolbar = toolbar => {
    return (
      <span>
        <div className="calendar-custom-header">
          <DateNavigationComponent toolbar={toolbar} calendarActiveView={calendarActiveView} />
          <div
            style={{
              display: 'flex',
              flexDirection: mobileView ? 'column' : 'row',
              float: 'right',
              gap: mobileView ? '10px' : '15px',
            }}
          >
            <Button
              className="calendar-create-session-button"
              onClick={() => {
                setshowAvailabilityPopUp(true);
                setIsSessionLoading(false);
              }}
            >
              <AddIcon />
              <p style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Avenir' }}>Add One-Time Availability</p>
            </Button>
            <Tooltip title="To schedule a client, please create a 1:1 service first." open={isDisabled} arrow>
              <span>
                <Button
                  className="calendar-create-session-button"
                  onClick={() => setShowSelfbookModal(true)}
                  disabled={isDisabled}
                >
                  <AddIcon />
                  <p style={{ fontWeight: '400', fontSize: '14px', fontFamily: 'Avenir' }}>Schedule Client</p>
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="attached-calendars">
          {calendars?.length ? (
            calendars?.map(calendar => {
              return (
                <ExternalCalendarVisibilityComponent
                  isPreviewMasterCalendarEvents={calendar?.isPreviewMasterCalendarEvents}
                  emailAddress={calendar?.emailAddress}
                  removeCalendar={emailAddress => {
                    setSelectedEmailToRemove(emailAddress);
                    setIsConfirmationModalOpen(true);
                  }}
                  style={{ borderColor: getCalendarColor(calendar.emailAddress) }}
                  onPreviewIconClick={isVisible => {
                    setIsSessionLoading(true);
                    getMasterCalendarEvents({
                      isPreviewMasterCalendarEvents: isVisible,
                      email: calendar?.emailAddress,
                    })
                      .then(() => {
                        fetchCalendars().then(() => {
                          handleStartAndEndDateOfView(currentViewDateRange, toolbar.view, true);
                        });
                      })
                      .finally(() => {
                        setIsSessionLoading(false);
                      });
                  }}
                  provider={calendar?.provider}
                  onSettingsClick={() => {
                    setCalendarForSettingsPopup(calendar);
                    setShowExternalCalendarSettingsModal(true);
                  }}
                  // closeSettings={() => {
                  //   setCalendarForSettingsPopup(null);
                  //   setShowExternalCalendarSettingsModal(false);
                  // }}
                />
              );
            })
          ) : (
            <></>
          )}

          <Button
            className="add-my-calendar-button"
            variant="secondary"
            onClick={() => {
              return isArray(calendars) && calendars.length
                ? setIsAddCalendarModalOpen(true)
                : addCalendar({ contributionId: null, isCreate: true, isMasterCalendar: true });
            }}
          >
            <AddIcon />
            <p style={{ fontWeight: '500', fontSize: '12px', fontFamily: 'Avenir' }}>Add My Calendar</p>
          </Button>
        </div>
      </span>
    );
  };

  return (
    <div className="master-calendar-container">
      {(isSessionLoading || isloading || isRescheduleLoading) && <Loader />}
      <BigCalendar
        className={`cohere-master-calendar custom-calendar custom-${prevView}-calendar-view`}
        style={{ color: 'black', overflow: 'auto' }}
        localizer={localizer}
        events={events}
        views={allViews}
        defaultView={Views.DAY}
        step={30}
        showMultiDayTimes
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        onShowMore={(dateEvents, date) => {
          setSelectedDateEvents({
            date,
            events: dateEvents,
          });
          setShowEventsPopup(true);
        }}
        onDrillDown={() => {}}
        onView={view => {
          if (view === 'day' || view === 'week') {
            setTimeout(() => {
              // Scroll to 9 AM when navigating to the Day view
              const calendar = document.getElementsByClassName('rbc-time-view')[0];
              if (calendar) {
                calendar.scrollTo({
                  top: 640,
                  behavior: 'smooth',
                });
              }
            }, 100);
          }
        }}
        formats={{ eventTimeRangeFormat: () => '', dayFormat: 'MMM D' }}
        components={{
          toolbar: CustomToolbar,
          event: event => CustomEvent({ ...event, view: prevView }),
        }}
        onRangeChange={handleStartAndEndDateOfView}
        onSelectEvent={onEventClick}
        eventPropGetter={({ color, start: eventStart, contributionType, isExternalEvent }, start, end, isSelected) => {
          const isPastEvent = moment(eventStart).isBefore(moment(), 'day');
          const nonMonthProps = {
            backgroundColor: '#F5F5F5',
            border: 'none',
            borderRadius: '0',
            borderLeft: `4px solid ${darken(0.1, color)}`,
            borderTop: `1px solid ${darken(0.1, color)}`,
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            paddingTop: '5px',
          };
          let newStyle = {
            color: color || colors.darkOceanBlue,
            backgroundColor: 'rgba(0,0,0,0)',
            minHeight: 24,
            fontSize: 14,
            fontWeight: 500,
            cursor: 'pointer',
            outline: isSelected && (isPastEvent || !contributionType) ? 'none' : 'inherit',
          };
          if (prevView !== 'month') {
            newStyle = {
              ...newStyle,
              ...nonMonthProps,
            };
          }
          return {
            className: '',
            style: newStyle,
          };
        }}
      />
      {isAddCalendarModalOpen && (
        <AddCalendarModal
          isOpen
          onModalClose={() => setIsAddCalendarModalOpen(false)}
          onSubmit={() => setIsAddCalendarModalOpen(false)}
          addCalendar={addCalendar}
          calendars={calendars}
          showsetInviteCalendarRow="false"
          integrationPage
          isMasterCalendar
          setCalendarForSettingsPopup={setCalendarForSettingsPopup}
          setShowExternalCalendarSettingsModal={setShowExternalCalendarSettingsModal}
        />
      )}
      <Modal
        height={'650px'}
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        onSubmit={() => {
          setRescheduleModalState(false);
        }}
        title="Reschedule your session"
        noPadding
        isBookingModal
        isDarkModeSelected={false}
        isCreatingContribution
        disableFooter
        CrossIconHide={!rescheduleSession}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal
            isCoach
            isMasterCalendar
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal
            isMasterCalendar
            handleCancel={() => setRescheduleModalState(false)}
          />
        )}
      </Modal>
      {showSelfbookModal && (
        <ScheduleMeetingModal
          key={showSelfbookModal}
          isVisible={showSelfbookModal}
          closeModal={() => {
            setShowSelfbookModal(false);
          }}
          onMasterCalendarComplete={() => refetchEvents()}
          setRescheduleSession={() => {
            setRescheduleSession(true);
            setRescheduleModalState(true);
            setShowSelfbookModal(false);
          }}
          title="Create Session"
          isCoach
          paymentInfo={contribution?.paymentInfo}
          setIsScheduleMeetingVisible={setShowSelfbookModal}
          selectedCalDate={selectedDate}
          fromMasterCalendar
          isMasterCalendar
        />
      )}
      {showRescheduleModal && (
        <ReschduleSessionPopup
          isModalOpen={showRescheduleModal}
          session={rescheduledSession}
          onCloseModal={() => setShowRescheduleModal(false)}
          onConfirm={onRescheduleSlotSelection}
          contribution={contribution}
        />
      )}
      {(showExternalCalendarSettingsModal ||
        showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
        showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
        <ExternalCalendarSettingsPopup
          isModalOpen={
            showExternalCalendarSettingsModal ||
            showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
            showExternalCalendarConnectionCompleteStepsModal.showStep2
          }
          // connectedCalendars={calendars}
          connectedCalendar={calendarForSettingsPopup}
          onCloseModal={() => {
            setCalendarForSettingsPopup(null);
            setShowExternalCalendarSettingsModal(false);
            setShowExternalCalendarConnectionCompleteStepsModal({
              ...showExternalCalendarConnectionCompleteStepsModal,
              showStep1: false,
              showStep2: false,
            });
          }}
          setShowExternalCalendarConnectionCompleteStepsModal={setShowExternalCalendarConnectionCompleteStepsModal}
          showExternalCalendarConnectionCompleteStepsModal={showExternalCalendarConnectionCompleteStepsModal}
          isMasterCalendarPage
          onSuccessSave={emailAddress => {
            setIsSessionLoading(true);
            fetchCalendars()
              .then(() => {
                handleStartAndEndDateOfView(currentViewDateRange, prevView, true);
              })
              .finally(() => {
                setIsSessionLoading(false);
              });
          }}
        />
      )}
      {showCustomMessageModal && (
        <Modal
          isOpen={showCustomMessageModal}
          onCancel={() => setShowCustomMessageModal(false)}
          onSubmit={onReschedule}
          title="Confirm your new session time"
          hiddenCancel
          submitTitle="Confirm Reschedule"
          widthRequired="850px"
          brandingColor={false}
          isCreatingContribution={true}
        >
          <BodyText style={{ overflow: 'hidden' }} className="test">
            By clicking Confirm, you will be updating the session time with {rescheduledSession?.clientName}. It’s your
            responsibility to confirm with {rescheduledSession?.clientName} that this new time also works for them.
          </BodyText>
          <TextField
            style={{ width: '100%' }}
            placeholder="Optional. What is the reason for rescheduling? This information will be shared with your client"
            multiline
            rowsMax={6}
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
            className="rescheduleResonInput"
          />
        </Modal>
      )}
      {showSuccessfulReschedulePop && (
        <Modal
          isOpen={showSuccessfulReschedulePop}
          onCancel={() => setShowSuccessfulReschedulePop(false)}
          onSubmit={() => setShowSuccessfulReschedulePop(false)}
          title="Reschedule Successful"
          width="800px"
          widthRequired="800px"
          hiddenCancel
          brandingColor={false}
          isCreatingContribution={true}
        >
          <BodyText style={{ fontFamily: 'Avenir', fontSize: '18px', fontWeight: '500px' }}>
            Session has been successfully reschedule on{' '}
            <span style={{ color: '#C9B382' }}>
              {' '}
              {formatTime(selectedSlot.start)}, {moment(selectedSlot.start).format('hh:mm A')}{' '}
            </span>
          </BodyText>
        </Modal>
      )}
      {showUnsuccessfulReschedulePop && (
        <RefuseModal
          className="master-calendar-refuse-model"
          isOpen
          onClose={() => setShowUnsuccessfulReschedulePop(false)}
          onSubmit={() => setShowUnsuccessfulReschedulePop(false)}
          message="Slot already booked"
        />
      )}
      {showEventsPopup && (
        <EventsPopup
          events={selectedDateEvents.events}
          date={selectedDateEvents.date}
          onEventClick={onEventClick}
          onCancel={() => setShowEventsPopup(false)}
        />
      )}
      {showExternalCalendarEventPopup && (
        <Modal
          className="external-event-popup"
          isOpen={showExternalCalendarEventPopup}
          onCancel={() => setShowExternalCalendarEventPopup(false)}
          title="External Calendar Event"
          cancelTitle="Close"
          disableConfirm
          brandingColor={false}
          isCreatingContribution={true}
        >
          <p>Please use parent calendar or some external calendar to edit this event</p>
        </Modal>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isOpen
          title="Confirm Action"
          submitTitle="Yes, Delete"
          cancelTitle="No, Cancel"
          onSubmit={() => {
            removeCalendar(selectedEmailToRemove).then(() => {
              refetchEvents();
            });
            setIsConfirmationModalOpen(false);
          }}
          onCancel={() => {
            setSelectedEmailToRemove('');
            setIsConfirmationModalOpen(false);
          }}
        >
          <p style={{ marginBottom: '10px' }}>
            <strong>Are you sure you want to DELETE this integrated calendar?</strong>
          </p>
          <span>If you remove this calendar, it will no longer be integrated with your availability.</span>
        </ConfirmationModal>
      )}
      <AvailabilityModal
        showTerms={showAvailabilityPopUp}
        setshowAvailabilityPopUp={setshowAvailabilityPopUp}
        onCancel={() => {
          setshowAvailabilityPopUp(false);
        }}
        incrementNewSlots={() => {}}
      />
    </div>
  );
};

const mapStateToProps = ({ calendars, upcomingCreated: { loading } }) => ({
  calendars: calendars.calendarsAccounts,
  isloading: calendars.loading,
  calendarActiveView: calendars.currentView,
});

const actions = {
  addCalendar: calendarsActions.addCalendar,
  fetchCalendars: calendarsActions.fetchCalendars,
  calendarSignInCallback: calendarsActions.calendarSignInCallback,
  removeCalendar: calendarsActions.removeCalendar,
  rescheduleChosenSession: rescheduleSessionAction,
};

MasterCalendarPage.propTypes = {
  addCalendar: PropTypes.func.isRequired,
  fetchCalendars: PropTypes.func.isRequired,
  removeCalendar: PropTypes.func.isRequired,
  calendars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  calendarActiveView: PropTypes.string.isRequired,
  isloading: PropTypes.bool.isRequired,
};
export default connect(mapStateToProps, actions)(MasterCalendarPage);
